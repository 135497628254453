/* Position and sizing of burger button */
.bmBurgerButton {
  position: relative;
  height: 1.25rem;
  width: 1.25rem;
}

/* Color/shape of burger icon bars */
.bmBurgerBars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bmBurgerBarsHover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bmCrossButton {
  height: 20px;
  width: 20px;
  top: 20px !important;
  right: 20px !important;
}

/* Color/shape of close button cross */
.bmCross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bmMenuWrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
  top: 0;
}

@screen md {
  .bmMenuWrap {
    width: 418px !important;
  }
}

/* General sidebar styles */
.bmMenu {
  @apply bg-base-200;
  padding-top: 5rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

/* Morph shape necessary with bubble or elastic */
.bmMorphShape {
  fill: #373a47;
}

/* Wrapper for item list */
.bmItemList {
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;

  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

/* Individual item */
.bmItem {
  display: inline-block;
}

/* Styling of overlay */
.bmOverlay {
  @apply bg-base-300;
  height: 100%;
  top: 0;
  right: 0;
}


/* 
Custom Menu Icon
*/
.burgerMenuIcon {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}

.burgerMenuIcon::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 5px;
  background: currentColor;
  box-shadow:
    0 8px 0,
    8px 8px 0,
    16px 8px 0,
    8px 16px 0,
    16px 16px 0,
    8px 0 0,
    16px 0 0,
    0 16px 0;
  border-radius: 22px;
}